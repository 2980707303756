<template>
    <div id="comments" class="comments">
        <div class="comments-header">
            <h1 class="comments-header__comments-total">
                {{ totalComentsCount }} comments
            </h1>
        </div>
        <form
            class="comments-add"
            novalidate
            @submit.prevent="addComment"
            @keyup.ctrl.enter="addComment"
        >
            <div class="d-flex w-100">
                <user-picture :picture="userData.photo? userData.photo.url : require('@assets/img/icons/avatar.png')" />
                <textarea
                    v-if="isLoggedIn"
                    v-model="newComment"
                    :style="`height: ${newCommentHeight}px`"
                    maxlength="500"
                    placeholder="Add a comment…"
                    @focus="newCommentHeight = 90"
                    @blur="newComment == '' ? (newCommentHeight = 26) : (newCommentHeight = 90)"
                />
                <textarea
                    v-else
                    rows="1"
                    placeholder="Add a comment…"
                    @click="$modal.show('auth-modal', { allowClose: true, showSignIn: true })"
                />
            </div>
            <transition name="slide-fade">
                <suggestions-list
                    v-if="suggestionString != ''"
                    :suggestion-string="suggestionString"
                    :suggestion-type="suggestionType"
                    @selected-suggestion="selectedSuggestion"
                />
            </transition>
            <transition name="fade">
                <div v-if="newComment.trim()" class="reply-box-actions mt-3 align-self-end">
                    <button class="btn btn-white">
                        Post
                    </button>
                </div>
            </transition>
        </form>
        <div
            v-infinite-scroll="fetchComments"
            class="comments-list"
            infinite-scroll-immediate-check="false"
            infinite-scroll-disabled="fetchingList"
            infinite-scroll-distance="100"
        >
            <transition-group name="slide-right" mode="out-in">
                <comment-component
                    v-for="comment in comments"
                    :key="comment.id"
                    :comment="comment"
                    :memod-id="memodId"
                    @toggle-like="toggleLikeOnList"
                    @deleted-comment="deleteCommentFromList"
                    @update-total-comments-counter="updateTotalCommentsCounter"
                />
            </transition-group>
        </div>
    </div>
</template>

<script>
import infiniteScroll from "vue-infinite-scroll";
import { mapGetters, mapState } from "vuex";

export default {
    name: "CommentsList",
    components: {
        UserPicture: () => import(/* webpackChunkName: "user-picture" */ "@/components/atoms/user-picture"),
        SuggestionsList: () => import(/* webpackChunkName: "suggestions-list" */ "@v/web-app/create/suggestions-list"),
        CommentComponent: () => import(/* comment-component */ "./comment")
    },
    directives: {
        infiniteScroll
    },
    props: {
        memodId: {
            type: Number,
            required: true
        },
        totalComments: {
            type: Number,
            default: 0
        }
    },
    data() {
        return {
            newComment: "",
            comments: [],
            suggestionString: "",
            suggestionType: "mentions",
            newCommentHeight: 26,
            commentsPagination: {
                limit: 10,
                page: null,
                total_pages: 1
            },
            fetchingList: false,
            totalComentsCount: 0
        }
    },
    computed: {
        ...mapGetters({
            isLoggedIn: "User/isLoggedIn"
        }),
        ...mapState({
            userData: state => state.User.data
        })
    },
    watch: {
        newComment() {
            this.checkMentions();
        },
        memodId: {
            immediate: true,
            handler() {
                this.resetComments();
                this.fetchComments();
            }
        },
        totalComments: {
            immediate: true,
            handler() {
                this.totalComentsCount = this.totalComments;
            }
        }
    },
    methods: {
        checkMentions() {
            if (this.newComment.match(/@\w+/g)) {
                this.suggestionString = this.newComment.match(/@\w+/g)[0];
            } else {
                this.suggestionString = "";
            }
        },
        selectedSuggestion(suggestion) {
            this.suggestionString = "";
            this.newComment = `${ this.newComment + suggestion }`;
        },
        resetComments() {
            this.newComment = "";
            this.comments = [];
            this.newCommentHeight = 26,
            this.commentsPagination.page = null;
            this.commentsPagination.total_pages = 1;
        },
        fetchComments() {
            const limit = this.commentsPagination.limit;
            const nextPage = this.commentsPagination.page ? (this.commentsPagination.page + 1) : 1;

            if (Number(nextPage) <= this.commentsPagination.total_pages) {
                this.fetchingList = true;
                axios.get(`/feeds/${this.memodId}/comments?format=true&limit=${limit}&page=${nextPage}&sort=created_at|desc`).then(({ data }) => {
                    this.commentsPagination.limit = data.limit;
                    this.commentsPagination.page = data.page;
                    this.commentsPagination.total_pages = data.total_pages;
                    for (const key in data.data) {
                        this.comments.push(data.data[key]);
                    }
                    this.fetchingList = false;
                }).catch(() => {
                    console.log("This memo has not comments yet")
                }).finally(() => {
                    this.fetchingList = false;
                });
            }
        },
        addComment() {
            if (!this.newComment.trim()) {
                return;
            }

            axios.post(`/feeds/${this.memodId}/comments`, { comment: this.newComment }).then(({ data }) => {
                this.newComment = "";
                this.newCommentHeight = 26;
                this.comments.unshift(data);
                this.updateTotalCommentsCounter(1);

                this.$amplitude.logEvent("Comment", {
                    entity_id: this.memodId,
                    event_name: "Comment",
                    type: "Created"
                });

            }).catch((error) => {
                console.log(error.response.data.errors.message);
            })
        },
        deleteCommentFromList(commentId) {
            const deletedCommentIndex = this.comments.findIndex((comment) => comment.id == commentId);
            this.comments.splice(deletedCommentIndex, 1);
        },
        toggleLikeOnList(commentId, data) {
            const toggleLikeCommentIndex = this.comments.findIndex((comment) => comment.id == commentId);
            this.comments[toggleLikeCommentIndex].is_liked = !this.comments[toggleLikeCommentIndex].is_liked;
            this.comments[toggleLikeCommentIndex].total_likes = data.total;
        },
        updateTotalCommentsCounter(number) {
            this.totalComentsCount = this.totalComentsCount + (Number(number) || 0);
        }
    }
}
</script>

<style lang="scss" scoped>
.comments {
    margin-top: 75px;
    position: relative;

    /deep/ .user-picture {
        margin-right: 10px;

        figure {
            width: 30px;
            height: 30px;
            background-color: #0F0F10;
        }
    }

    .comments-header {
        border-top: 1px solid rgba(84, 84, 88, 0.65);
        border-bottom: 1px solid rgba(84, 84, 88, 0.65);
        padding: 15px;

        &__comments-total {
            font-size: 19px;
            line-height: 19px;
        }
    }

    .comments-add {
        position: relative;
        padding: 15px;
        display: flex;
        flex-direction: column;
        border-bottom: 1px solid rgba(84, 84, 88, 0.65);
        align-items: center;

        /deep/ .user-picture {
            margin-right: 10px;
        }

        textarea {
            width: 100%;
            background-color: transparent;
            font-size: 16px;
            line-height: 16px;
            border: 0;
            color: white;
            padding-top: 8px;
            height: 100%;
            transition: height .3s;

            &::placeholder {
                color: white;
            }

            &:focus {
                outline: 0;
            }
        }
    }

    .comments-list {
        padding: 15px;
    }
}
</style>
